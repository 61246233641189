import "./about.scss";
import BackButton from "../../components/backButton/BackButton";
import Background from "../../components/background/background";
import React from "react";

export default function About(props){
    const { title, content,style } = props;
    return(
        <div id="about-container">
            <Background ornamentConfig={[true,false,false, true]} textConfig={false} style={style}/>
            <div style={style.backIcon}>
                <BackButton />
            </div>
            <div id='about-content'>
                <div id="about-title">
                    {title}
                </div>
                <div>
                    {
                        content.map((content,index)=>{
                            return <div  key={index} className='lineBetween'>{content}</div>
                        })
                    }
                </div>
            </div>

        </div>
    )
}