import Congrats from "./Congrats";
import leftPlant from "./assets/left-plant.svg";
import rightPlant from "./assets/right-plant.svg";
import CardFrame from "../../assets/card-frame.png";
import nft from "../../assets/nft.png";

export default function CongratsConfigsPage(){
    return(
        <Congrats plant={plant} style={style} title={'数字藏品名称'} buttonContent={'确认'}/>
    )
}

const plant = {left: leftPlant, right: rightPlant};

const style = {
    button: {
        buttonBackgroundColor: "#281EC8",
        buttonContentColor: "#ffffff",
    },
    titleStyle: {
        color: "#ffffff",
        fontSize: "15px",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        marginTop: '61px'
    },
    background: {
      marginTop: '81px'
    },
    frame:{
        height: '356px',
        width: '293px',
        backgroundImage: CardFrame,
        contentImage: nft,
        content:{
            height: '266px',
            width: '266px'
        }
    }
};