import BackButton from "../../components/backButton/BackButton";
import "./claim.scss"
import React, {useContext, useState} from "react";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import {useNavigate} from "react-router";
import {bottomToast} from "../../utils/toast";
import Frame from "../../components/frame/frame";
import Background from "../../components/background/background";

export default function Claim(props){
    const { style } = props;
    const [code, setCode] = useState();
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();

    const handleClaim = () => {
        if(!state.isLogin){
            navigate("/login");
        }else{
            try {
                fetch(
                    serverAddress + "/nfts/redemption",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"collectionId": 6, "code": code })
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nft = result.data.nfts;
                            let {name,tokenId,thumbnailURL,collectionInfo} = nft[0];
                            navigate("/congrats?name=" + name + "&tokenId=" + tokenId + "&url=" + thumbnailURL+"&desc=" + collectionInfo.description);
                        }else if(result.returnCode === responseCode.redemptionCodeInvalid){
                            bottomToast("请输入正确的领取码");

                        }else if(result.returnCode === responseCode.redemptionCodeAlreadyUsed){
                            bottomToast("该兑换码已使用");
                        }else if(result.returnCode === "24000"){
                            bottomToast("请输入正确的领取码");
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }

    }
    return(
        <div id="claim-container">
            <Background ornamentConfig={[true,true,true, false]} textConfig={false} style={style}/>
            <div id="claim-content">
                <Frame height={style.frame.height} width={style.frame.width}
                       contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
                       backgroundImage={style.frame.backgroundImage} contentImage={style.frame.contentImage}
                />
                <div id="code-input">
                    <input size="1" maxLength="8"
                           onChange={(e) => {setCode(e.target.value)}}
                           onClick={(e) => {e.target.scrollIntoView({inline: "start"})}}
                           placeholder="输入领取码"/>
                </div>

                <div id='claim-button' onClick={handleClaim} style={{ backgroundColor: style.button.backgroundColor, color: style.button.color }}>
                    {style.button.content.text}
                </div>
            </div>
        </div>
    )
}