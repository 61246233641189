import {useNavigate} from "react-router";
import "./congrats.scss";
import Loading from "../../components/loading/Loading";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BackgroundImage from '../../assets/background.png';
import CommonTitle from "../../components/commonTitle/commonTitle";
import Frame from "../../components/frame/frame";
import Background from "../../components/background/background";
import Desc from "../../components/autoSwiper/components/desc";
import DutyFree from "../../components/DutyFree";
import {AuthContext, serverAddress} from "../../App";

export default function Congrats(props){
    const { title, plant, style, buttonContent} =props;
    let navigate = useNavigate();
    let [params] = useSearchParams();
    const { state } = useContext(AuthContext);

    const confirm = () => {
        navigate('/list');
    }

    const url = params.get("url");
    const name = params.get("name");
    const desc = params.get("desc");

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        },1000)
    },[])
    return(
        <div id="congrats-container">
            <Background ornamentConfig={[true,true,true, false]} textConfig={true} style={style}/>
            <CommonTitle plant={plant} style={style} title={name}/>
            <Frame height={style.frame.height} width={style.frame.width}
                   contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
                   backgroundImage={style.frame.backgroundImage} contentImage={url}
                   lightBackground={true}/>
            <Desc desc={desc}/>
            <div id='retrieve-button' onClick={confirm} style={{ backgroundColor: style.button.buttonBackgroundColor, color: style.button.buttonContentColor}}>
                {buttonContent}
            </div>
            <div className='duty-container'>
                <DutyFree />
            </div>
            <Loading isShowing={!loaded} backgroundImage={BackgroundImage} text={'努力生成中...'}/>
        </div>
    )
}