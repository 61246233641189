import back from "./assets/back.png";
import {useNavigate} from "react-router";
import { useLocation } from "react-router";
import blackBack from './assets/blackBack.svg';

export default function BackButton(){
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const goBack = () => {
        navigate(-1)
    }

    const getBackButton = ()=>{
        switch (currentLocation.pathname){
            case '/login':
                return blackBack;
            default: return back;
        }
    }

    return (
        <div onClick={goBack} style={{width:'20px', height:'16px', position: 'absolute'}}>
            {currentLocation.pathname === '/claim'? null:  <img src={getBackButton()} style={{width:'100%',height:'100%'}} alt=''/> }
        </div>
    )
}