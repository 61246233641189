import './previousCollection.scss'
import PreviousFrame from "../previousFrame/previousFrame";

export default function PreviousCollection (props) {
  const {previousNftInfo} = props
  return (
    <div className="previous-collection-container">
      <div className='title'></div>
      {previousNftInfo.map((nftInfo, index)=>{
        return <PreviousFrame nftInfo={nftInfo} key={index}/>
      })}
    </div>
  )
}