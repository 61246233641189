import BackButton from "../../components/backButton/BackButton";
import "./detail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { serverAddress } from "../../App";
import { responseCode } from "../../config";
import DutyFree from "../../components/DutyFree";
import { Link } from "react-router-dom";
import Background from "../../components/background/background";
import Frame from "../../components/frame/frame";
import CommonTitle from "../../components/commonTitle/commonTitle";
import DetailFrame from "../../components/detailFrame/detailFrame";

export default function Detail(props) {
    const { title, subTitle, plant, style } = props;
    let { id } = useParams();
    let [nftInfo, setInfo] = useState({});

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator } = result.data;
                        setInfo({ ownedAt, name, ownerUserInfo, displayURL, publisher: collectionInfo.publisher, title: title, address: collectionInfo.contractAddress, creator: creator });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    return (
        <div id="detail-container">
            <Background ornamentConfig={[true,false,false, false]} textConfig={true} style={style}/>
            <div
                style={{
                    margin: "25px 0 0 20px",
                    position: "absolute",
                    left: "0",
                    top: "0",
                }}
            >
                <BackButton />
            </div>
            <CommonTitle title={nftInfo.name} plant={plant} style={style}
                         subTitle={`合约地址:${nftInfo? nftInfo.address: '' }`}/>
            <Frame height={style.frame.height} width={style.frame.width}
                   contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
                   backgroundImage={style.frame.backgroundImage} contentImage={nftInfo.displayURL}
                   lightBackground={true}
            />
            <DetailFrame nftInfo={nftInfo}/>
            <DutyFree />
            <Link to={"/poster/" + id} style={{zIndex: '5', textDecoration: 'none'}}>
            <div className="generate-poster">
                生成海报
            </div>
            </Link>
        </div>
    );
}
