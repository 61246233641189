import { Login } from "./Login";
import backgroundImage from "../../assets/background-login.png";
import logo from "../../assets/logo.png";

export default function LoginConfigPage() {
  return (
    <Login
      style={style}
      logo={logo}
      // backgroundImage={backgroundImage}
    /> 
  );
}

export const style = {
  loginButton: {
    //login button 登录按钮
    buttonTextColor: "#FFFFFF",
    buttonBackgroundColor: "#281EC8",
    // login button disabled 登录按钮禁用
    buttonTextDisableColor: "#FFFFFF",
    buttonDisableBackgroundColor: "#281EC8",
  },
  codeButton: {
    textColor: '#281EC8',
  },
  userAgreement: {
    descriptionTextColor: "#C7C7C7",
    userAgreementTextColor: "#281EC8",
  },
  checkBox: {
    // check box checked color  勾选框勾选颜色
    checked: "#281EC8",
  },
  loginPage: {
    //login page background color 登录页背景色
    backgroundColor: "#ffffff",
  },
};
