import "./loading.scss"
import {Box, LinearProgress, linearProgressClasses} from "@mui/material";
import styled from "@emotion/styled";
import LoadingGif from './assets/loading.gif';
import LoadingText from './assets/text.svg';
import Wave from './assets/wave.svg';

export default function Loading( {text,isShowing, backgroundImage} ){
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 13,
        margin: '1.5px',
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'rgba(0,0,0,0)',
        },
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#493EF4',
        },
    }));
    return (
        <div className="fullscreen-loading" style={{display: isShowing ? 'flex' : 'none', backgroundImage: `url(${backgroundImage})`}}>
            <Box className="loading-container"  sx={{ width: '115px',height: "16px",border: '1px solid #493EF4',borderRadius:'10px'}}>
                <BorderLinearProgress />
            </Box>
            {/*<img src = { LoadingGif } className='loading-container'/>*/}
            <img src = { Wave } className='wave-container'/>
            <div className={'text-container'}>{text}</div>
            {/*<img src= {LoadingText} className='text-container'/>*/}
        </div>
    )
}