import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "./autoswiper.scss";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Autoplay } from "swiper";
import {useState} from "react";
import Desc from './components/desc';
import Header from './components/header';
import Frame from "../frame/frame";

SwiperCore.use([FreeMode, Autoplay]);

export default function AutoSwiper(props){
    const {style, nftInfo, swiperContainer, spaceBetween, handleSlideChange, currentIndex} = props;

    return(
        <div style={{ height: style.swiper.height, width: style.swiper.width, position: 'absolute', marginTop: '91px'}}>
            {/* <div className="nft-id">*/}
            {/*  { nftInfo[currentIndex].header? <Header header={nftInfo[currentIndex].header} /> : null}*/}
            {/*</div>*/}
            <Swiper slidesPerView={'auto'}
                    spaceBetween={spaceBetween} centeredSlides={true} autoplay={nftInfo.length >1 ? {delay: 1000,disableOnInteraction: false}: false} loop={nftInfo.length >1}
                    className="home-swiper"
                    onSlideChange={handleSlideChange}>
                {
                    nftInfo.map((nft,index)=>{
                        return(
                            <SwiperSlide style={{height: style.swiper.container.height, width: style.swiper.container.width}} key={index}>
                                <Frame backgroundImage={swiperContainer} contentImage={nft.image}
                                height={style.swiper.container.height} width={style.swiper.container.width}
                                contentHeight={style.swiper.content.height} contentWidth={style.swiper.content.height}
                                desc={nftInfo[currentIndex].desc? <Desc desc={nftInfo[currentIndex].desc}/>: null}
                                />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
          { nftInfo[currentIndex].desc? <Desc desc={nftInfo[currentIndex].desc}/>: null}
        </div>
    )

}