import './previousFrame.scss'
import {AuthContext} from "../../App";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

export default function PreviousFrame (props) {
  const {image, header, avatar, authorName, available} = props.nftInfo
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const claim = () => {
    if(state.isLogin){
        navigate('/claim')
    }else{
        navigate('/login');
    }
}
  return (
    <div className="previous-frame-container">
      <div className="nft-image-container">
        <img className="nft-image" src={image} alt=''/>
      </div>
      <div className="nft-header">{header}</div>
      <div className="author-container">
        <img className="avatar" src={avatar} alt=''/>
        <div className="author-name">{authorName}</div>
      </div>
      {available?<button className="collect-btn" onClick={claim}>立即领取</button>:<button className="disabled-btn">已售罄</button>}
    </div>
  )
}