import './commonTitle.scss';

export default function CommonTitle(props){
    const {style, title, plant, subTitle, subTitleBackground} = props;

    const getSubStr = (str) => {
        if(str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    return(
        <div className="home-container" >
            <div className="home-title" style={{ marginTop: style.titleStyle.marginTop }}>
                <div className="plant-container">
                    <img src={plant.left} alt=''/>
                </div>
                <div style={{ color: style.titleStyle.color, paddingLeft:style.titleStyle.paddingLeft,
                              paddingRight: style.titleStyle.paddingRight, paddingBottom: style.titleStyle.paddingBottom,
                              maxWidth: '250px', wordBreak: 'break-all', boxSizing: 'border-box', height: '60px'
                }}>
                    {title}
                </div>
                <div className="plant-container">
                    <img src={plant.right} alt=''/>
                </div>
            </div>
            <div className={subTitleBackground? 'sub-title-light':"sub-title"}>
                {subTitle?getSubStr(subTitle):''}
            </div>
        </div>
    )
}