import React from "react";
import { Home } from "./Home.js";
import leftPlant from "./assets/left-plant.svg";
import rightPlant from "./assets/right-plant.svg";
import swiperContainer from '../../assets/swiperBackground.png';
import Background from "../../components/background/background";
import PreviousCollection from "../../components/previousCollection/previousCollection"
import gif1 from './assets/1.gif';
import gif2 from './assets/2.gif';
import pic from './assets/3.jpg';
import pic2 from './assets/4.jpg';
import pic3 from './assets/5.png';
import libai from './assets/libai-image.jpg';
import previous1 from './assets/previousNft/previous1.png';
import previous2 from './assets/previousNft/previous2.png';
import defaultAvatar from './assets/defaultAvatar.png'
import aolan from './assets/aolan.png'
import definex from './assets/definex.png'
import gd from './assets/gd.png'
import jxb from './assets/jxb.png'

export default function HomePageConfig() {
  return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Background ornamentConfig={previousNftInfo.length>0?[true,true,false, false, true]:[true,true,true, false, false]} textConfig={true} style={style}/>
        <Home
            plant={plant}
            style={style}
            // title={"有点东西"}
            claimContent={"立即领取"}
            nftInfo={nftInfo}
            swiperContainer={swiperContainer}
        />
        {previousNftInfo.length>0?<PreviousCollection previousNftInfo={previousNftInfo}/>:null}
      </div>
  );
}

const previousNftInfo = [
  // {image: previous1, header: '新城渝北-潮玩小老虎数字藏品', avatar: defaultAvatar, authorName: '奈雪的茶 x cocafe', available: true},
  // {image: previous2, header: '新城渝北-潮玩小老虎数字藏品', avatar: defaultAvatar, authorName: '奈雪的茶 x cocafe', available: false},
  {image: gif1, header: '池磊xDEFINEX《虎啸雷霆》联名', desc: '以“虎啸雷霆”作为主题致敬中国传统年画，诠释品牌对街头文化的独特视野，展现时代潮流契合度。', avatar: definex, authorName: 'DEFINEX x 咖菲科技', available: true},
  {image: gif2, header: '奥兰小红帽', desc: 'Knock Knock,去闹吧',avatar: aolan, authorName: '奥兰', available: true},
  {image: pic, header: '如花在野.奥妙若兰No.5', desc: '如花般野蛮生长，自在绽放生命无限元力。若兰般淡然开放，自在探索宇宙奥妙之光。',avatar: aolan, authorName: '奥兰 x 洛可可', available: true},
  {image: pic2, header: '《歌德老酒行 · 瓶瓶都保真》', desc: '由歌德老酒行限量首发500份，旨在为各位酒友提供优质、保真的老酒服务。', avatar: gd, authorName: '歌德老酒行 x 咖菲科技', available: true},
  {image: pic3, header: '红蹦蹦 RED Boom boom', desc: '情绪失控是当下瓶子星球最尖锐的问题，人们迫切需要点燃情绪的超级英雄。江小白热血战士，红蹦蹦 RED boom boom高燃来袭。',avatar: jxb, authorName: '江小白', available: true}
]
const nftInfo = [
    {image: libai, header: '立白大师香氛知香鸟', desc: '立白大师香氛，以知香鸟谱写香氛传奇，让洗衣变成一种享受。'},
    // {image: gif1, header: '池磊 x DEFINEX《虎啸雷霆》联名系列数字藏品', desc: '以“虎啸雷霆”作为主题致敬中国传统年画，诠释品牌对街头文化的独特视野，展现时代潮流契合度。'},
    // {image: gif2, header: '奥兰小红帽', desc: 'Knock Knock,去闹吧'},
    // {image: pic, header: '如花在野.奥妙若兰No.5', desc: '如花般野蛮生长，自在绽放生命无限元力。若兰般淡然开放，自在探索宇宙奥妙之光。'},
    // {image: pic2, header: '《歌德老酒行 · 瓶瓶都保真》', desc: '由歌德老酒行限量首发500份，旨在为各位酒友提供优质、保真的老酒服务。'},
    // {image: pic3, header: '红蹦蹦 RED Boom boom', desc: '情绪失控是当下瓶子星球最尖锐的问题，人们迫切需要点燃情绪的超级英雄。江小白热血战士，红蹦蹦 RED boom boom高燃来袭。'}
]

const plant = {left: leftPlant, right: rightPlant};

const style = {
  claimButton: {
    buttonBackgroundColor: "#281EC8",
    buttonContentColor: "#ffffff",
  },
  titleStyle: {
    color: "#ffffff",
    fontSize: "15px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    marginTop: '61px'
  },
    background:{
      marginTop: '81px'
    },
  swiper: {
      height: "420px",
      width: "100%",
    container:{
      height:'356px',
      width: '293px'
    },
    content:{
      height: '266px',
      width: '266px'
    }
  },
};
