import React from 'react';
import './desc.scss';
import Quotation1 from '../assets/quotation1.svg';
import Quotation2 from '../assets/quotation2.svg';

export default function Desc(props) {
  const {desc} =props;
  
  return(
       desc && desc.length> 0? <div className="desc-container">
          <div>
              <img src={Quotation1} alt='' style={{ paddingBottom: '20px', paddingRight: '10px' }}/>
          </div>
          <div className="desc-content">
              {desc}
          </div>
          <img src={Quotation2} alt='' style={{ paddingTop: '20px',paddingLeft: '10px' }}/>
      </div>: null
  )
}