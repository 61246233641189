import React from "react";
import './detailFrame.scss';

export default function DetailFrame(props){
    const {nftInfo, style} = props;

    const getSubStr = (str) => {
        let subStr1 = str.substr(0, 7);
        let subStr2 = str.substr(str.length - 19, 19);
        return subStr1 + "..." + subStr2;
    };

    return(
        <div className="info-container" style={style}>
            <div className="glass-background">
                <div
                    style={{ marginBottom: "9px", fontSize: "18px", color: "white", height: '30px' }}
                >
                    {nftInfo.title}
                </div>
                <div className="detail-container">
                    <div className="detail-pair">
                        <div className="detail-key">发行方</div>
                        <div className="detail-value">{nftInfo.publisher}</div>
                    </div>
                    <div className="detail-pair">
                        <div className="detail-key">创建时间</div>
                        <div className="detail-value">{nftInfo.ownedAt?nftInfo.ownedAt.slice(0,10) : null}</div>
                    </div>
                    <div className="detail-pair">
                        <div className="detail-key">创作作者</div>
                        <div className="detail-value">{nftInfo.creator}</div>
                    </div>
                    <div className="detail-pair">
                        <div className="detail-key">区块链唯一编号</div>
                        <div className="detail-value">{nftInfo.address ?getSubStr(nftInfo.address): ''}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}